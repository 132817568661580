body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MedievalSharp";
  src: local("MedievalSharp"),
    url(./fonts/MedievalSharp-Regular.ttf) format("truetype");
}

  /* bootstrap customization
 @import "~bootstrap/scss/functions";
 @import "~bootstrap/scss/variables";

 $custom-spacers: (
   6: $spacer * 5,
 );

 $spacers: map-merge($custom-spacers, $spacers);

 @import "~bootstrap/scss/bootstrap"; 
 */

