.App {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-tabs .nav-item {
  display: inline;
  border: none !important;
  padding-left: 0px;
  color: black;
}

.nav-link {
  color: #6c757d !important;
}

.nav-link:hover {
  color: black !important;
}

.nav-link.active {
  color: black !important;
}

#controlled-tab-example-tabpane-work {
  margin-top: 0px;
}

.vertical-timeline {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.vertical-timeline::before {
  width: 2px;
  left: 22px !important;
  background: rgba(0, 0, 0, 0.125) !important;
}

.vertical-timeline-element-date {
  padding: 0px !important;
}

.vertical-timeline-element-icon {
  box-shadow: none !important;
}

.vertical-timeline-element-icon svg {
  height: 30px !important;
  width: 30px !important;
  margin-top: -15px;
}

.vertical-timeline-element-content-arrow {
  border-right-color: rgba(0, 0, 0, 0.125) !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 1px !important;
}

.blah {
  word-wrap: no-wrap;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.card-body {
  background-color: transparent !important;
}

.card-footer {
  background-color: transparent !important;
}

.card-img-top {
  border-radius: 0px !important;
}

.hidden-link {
  color: black !important;
  text-decoration: none !important;
}

button:focus {
  box-shadow: none !important;
}

.social-icon {
  color: rgba(108, 117, 125, 0.7);
}
.social-icon.instagram:hover {
  color: #833ab4;
}
.social-icon.youtube:hover {
  color: #ff0000;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card-columns {
  column-count: 4 !important;
}

#accordion-card-header-hide {
  background-color: transparent !important;
}

@media (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .hidden-below-lg {
    display: none !important;
  }
}

@media (max-width: 766px) {
  .hidden-mlg {
    display: none !important;
  }
}

@media (min-width: 766px) {
  .hidden-above-mlg {
    display: none !important;
  }
}

@media (min-width: 574px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 574px) {
  .hidden-above-md {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .hidden-sm {
    display: none !important;
  }
  .card-columns {
    column-count: 3 !important;
  }
}

@media (max-width: 574px) {
  .hidden-xs {
    display: none !important;
  }
  .card-columns {
    column-count: 1 !important;
  }
}

/* Ride with GPS styling */
.orange {
  background-color: #ea6d2c;
}
.orange td {
  color: white;
}
.orange td small.text-muted {
  color: #495057 !important;
}
.hover-orange:hover {
  background-color: #ea6d2c;
}
.hover-gray:hover {
  background-color: #f3f3f3;
}

.premium {
  background-color: #a8a8a8;
}

.nav-item-hover:hover {
  color: #dc3545;
}

.bm-burger-button {
  display: none;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -80px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 80px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 80px;
}

.btn-tag {
  color: rgb(42, 42, 42) !important;
  --bs-bg-opacity: 0.075;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
  padding-left: 0.8em !important;
  padding-right: 0.8em !important;
}
.btn-tag:hover {
  --bs-bg-opacity: 0.05;
  background-color: rgb(225, 225, 225) !important;
}

.filter-green {
  filter: opacity(50%);
  cursor: pointer;
}

.filter-green:hover {
  filter: opacity(100%);
}

.custom-shadow {
  filter: drop-shadow(5px 5px 8px lightgray);
}
